import { defineStore, acceptHMRUpdate } from "pinia";
import { useAsyncFetch } from "@/composables/useAsyncFetch";
import { useConfigStore } from "@/stores/useConfig";
import { useStyleStore } from "@/stores/useStyleApp";
import { reactive } from "vue";
import api from "@/services/channelService";
import { ChannelDetail } from "@/types/channelTypes";
import { Media } from "@/types/mediaTypes";
import { useRoute } from "vue-router";

export const useChannelStore = defineStore("channelStore", () => {
    const state = reactive({
      details: {},
      media: [],
      totalCount: 0,
      totalAudioCount: 0,
      totalImageCount: 0,
      totalVideoCount: 0,
      isFetchingAllMedia: false,
      isFetchingMoreMedia: false,
      isFetchingDetails: false
    });

    const { setList } = useStyleStore();
    const route = useRoute();
    const { filters, apiConfig, setMaxPage } = useConfigStore();
    const { payloadConfig } = apiConfig;

    async function fetchDetails(id) {
      state.isFetchingDetails = true;
      try {
        const { state: resState } = await useAsyncFetch<ChannelDetail, Object
        >(api.getChannelDetails({ id }));
        state.details = resState.data;
        // if (resState.data?.channelDesign) {
        //   setList(resState.data.channelDesign);
        // }
        return resState;
      } catch (e) {
        e.channelId = id;
        throw e;
      } finally {
        state.isFetchingDetails = false;
      }
    }

    async function fetchMedia(channelId) {
      state.isFetchingAllMedia = true;

      let chainedType = "";
      let resStateForCounts;

      try {
        // We have to fetch additional to get the correct count
        if (filters.channel.type !== "allMedia") {
           chainedType += `&filter=type=${filters.channel.type}`;
          const queryString = `?page=${payloadConfig.channel.page}&perpage=${payloadConfig.channel.PER_PAGE}&sort=${filters.channel.property}${filters.channel.sort}${chainedType}`;
          const { state: headerCountResponse } = await useAsyncFetch<Media, Object>(api.getChannelMedia({
            id: channelId
          }));
          resStateForCounts = headerCountResponse;
          setMediaCounts(resStateForCounts);
          const { state: filteredDataResponse } = await useAsyncFetch<Media, Object>(api.getChannelMedia({
            id: channelId,
            queryString
          }));
          state.media = filteredDataResponse.data;
        } else {
          // Create Query String
          const queryString = `?perpage=${payloadConfig.channel.PER_PAGE}&sort=${filters.channel.property}${filters.channel.sort}${chainedType}`;
          // Fetch query
          if (route.name === "ChannelMediaDetail") {
            const { state: resState } = await useAsyncFetch<Media, Object>(api.getChannelMedia({
              id: channelId,
              queryString
            }));
            resStateForCounts = resState;
          } else {
            const { state: resState } = await useAsyncFetch<Media, Object>(api.getChannelMedia({
              id: channelId,
              queryString
            }));
            resStateForCounts = resState;
          }
          // @ts-ignore
          if (route?.params?.channelId === channelId) {
            setMediaCounts(resStateForCounts);
            state.media = resStateForCounts.data;
          }
          return resStateForCounts;
        }
      } catch (e) {
        e.channelId = channelId;
        console.error(e);

      } finally {
        state.isFetchingAllMedia = false;
      }
    }

    function setMediaCounts(resState) {
      // @ts-ignore
      const totalCount = +resState.headers.get("x-total-count");
      // @ts-ignore
      state.totalAudioCount = +resState.headers.get("x-audio-count");
      // @ts-ignore
      state.totalImageCount = +resState.headers.get("x-image-count");
      // @ts-ignore
      state.totalVideoCount = +resState.headers.get("x-video-count");
      state.totalCount = +totalCount;

      setMaxPage({ storeKey: "channel", count: totalCount });
    }

    async function fetchMoreMedia(channelId) {
      state.isFetchingMoreMedia = true;
      let chainedType = "";
      if (filters.channel.type !== "allMedia") {
        chainedType += `&filter=type=${filters.channel.type}`;
      }
      try {
        // Create Query String
        if(payloadConfig.channel.page < payloadConfig.channel.MAX_PAGE){
          let queryString = `?page=${payloadConfig.channel.page}&perpage=${payloadConfig.channel.PER_PAGE}&sort=${filters.channel.property}${filters.channel.sort}${chainedType}`;
          // Fetch query
          const { state: resState } = await useAsyncFetch<Media[], Object>(api.getChannelMedia({
            id: channelId,
            queryString
          }));
          // append new data to state
          state.media.push(...resState.data);
          return resState;
        }

      } catch (e) {
        e.channelId = channelId;
        throw e;
      } finally {
        state.isFetchingMoreMedia = false;
      }
    }

    function resetState() {
      state.details = {};
      state.media = [];
      state.totalCount = 0;
      state.totalAudioCount = 0;
      state.totalImageCount = 0;
      state.totalVideoCount = 0;
      state.isFetchingAllMedia = false;
      state.isFetchingMoreMedia = false;
      state.isFetchingDetails = false;
    }

    return {
      fetchDetails, fetchMedia, fetchMoreMedia, resetState, state
    };
  })
;
// Import HMR module for pinia
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useChannelStore, import.meta.hot));
}
