<template>
  <transition name="fade" appear>
    <component :is="layoutComponent">
      <router-view v-slot="{ Component }" class="router-view">
        <!--        <transition name="fade" mode="out-in">-->
        <component :is="Component" />
        <!--        </transition>-->
      </router-view>
    </component>
  </transition>
</template>

<script lang="ts" setup>
// *** IMPORT COMPONENTS ** //
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import DetailLayout from "@/layouts/DetailLayout.vue";
// *** IMPORT FUNCTIONS ** //
import { computed, watch, watchEffect, onErrorCaptured, onBeforeMount } from 'vue';
import { useRoute, useRouter } from "vue-router";
// ** IMPORTS STORE ** //
import { useStyleStore } from "@/stores/useStyleApp";
import { storeToRefs } from "pinia";
import { useConfigStore } from "@/stores/useConfig";
import { useChannelStore } from "@/stores/useChannel";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/stores/useApp";
import "video.js/dist/video-js.css";
import "@sd/sd-player-plugin/dist/sd-player-plugin.css";

// ** STORE ** //
const { apiConfig, appSettings, publicPortalLanguage, getTenantResources, setLayout } = useConfigStore();
const styleStore = useStyleStore();
const { fetchMedia } = useChannelStore();
const route = useRoute();
const router = useRouter();
const { locale } = useI18n();
const { updateState } = useAppStore();
// ** STORE STATE ** //
const { designList, tenantDesignList, headerFontUrl, bodyFontUrl } = storeToRefs(styleStore);
// ** STORE ACTIONS ** //
const { displayProtectedImage, displayProtectedFont, setRootsVariables } = styleStore;

const layoutComponent = computed(() => {
  if (appSettings.layout === "detail") {
    return DetailLayout;
  } else {
    return DefaultLayout;
  }
});

function checkRouteQuery(query: { standAlone?: string, loop?: boolean, autoplay?: boolean, captionOn?: boolean, hideControls?: boolean, mediaCover?: boolean, pdfPage: number}) {
  if (query?.standAlone) {
    updateState({ key: "isStandAlone", value: !!query.standAlone });
  }
  if(query?.loop) {
    updateState({ key: "isLoop", value: !!query.loop });
  }
  if(query?.autoplay) {
    updateState({ key: "autoPlay", value: !!query.autoplay });
  }
  if(query?.hideControls) {
    updateState({ key: "hideControls", value: !!query.hideControls });
  }
  if(query?.mediaCover) {
    updateState({ key: "mediaCover", value: !!query.mediaCover });
  }
  if(query?.captionOn) {
    updateState({ key: "captionOn", value: !!query.captionOn });
  }
  if(query?.pdfPage) {
    updateState({ key: "pdfPage", value: query.pdfPage });
  }
}

// ** FUNCTIONS ** //

// fetching IMAGES!
const loadLogo = async () => {
  await displayProtectedImage(
    document.getElementById("logo"),
    `${tenantDesignList.value.logoUrl}`
  );
};
const loadFavicon = async () => {
  await displayProtectedImage(
    document.querySelector("link[rel~='icon']"),
    `${tenantDesignList.value.faviconUrl}`
  );
};
const loadFacebookImg = async () => {
  await displayProtectedImage(
    document.getElementById("facebook"),
    `${tenantDesignList.value.facebookShareImageUrl}`
  );
};
const loadTwitterImg = async () => {
  await displayProtectedImage(
    document.getElementById("twitter"),
    `${tenantDesignList.value.twitterShareImageUrl}`
  );
};

// fetching FONTS
// const loadHeaderFont = async (fontUrl) => {
//   if (fontUrl) {
//     return await displayProtectedFont(`${fontUrl}`, "header");
//   }
// };
//
// const loadBodyFont = async (fontUrl) => {
//   if (fontUrl) {
//     await displayProtectedFont(
//       `${fontUrl}`,
//       "body"
//     );
//   }
// };

const setFontUrls = ({headerFont, bodyFont}) => {

  if (bodyFont) {
    const style = document.createElement("style");
    style.textContent = `
    @font-face {
      font-family: 'bodyFont';
      src: url(${bodyFont}) format(woff);
    }
    `;
    document.body.append(style);
  }
    if(headerFont){
      const style = document.createElement("style");
      style.textContent = `
    @font-face {
      font-family: 'headerFont';
      src: url(${headerFont}) format(woff);
    }
    `;
      document.body.append(style);
    }
};

watch(
  () => route.params.channelId,
  async newId => {
    if (!newId) return; // Guard clause
    await fetchMedia(newId);

    // await loadBodyFont(tenantDesignList.value.bodyfontUrl);
  }
);

watch(route, (route) => {
  checkRouteQuery(route.query);
  if(route.name === 'ChannelMediaDetail' || route.name === 'MediaDetail') {
    setLayout('detail')
  } else {
    setLayout('default')
  }
}, { immediate: true });

// Prevent fetching another time
let hasLoadedHeaderFonts = false;
let hasLoadedFacebookImg = false;
let hasLoadedTwitterImg = false;
let hasLoadedFavicon = false;
let hasLoadedLogo = false;
watchEffect(async () => {
  // Cache and retrieve from store
  try {
    if ((designList.value?.headerfontUrl || tenantDesignList.value?.headerfontUrl || tenantDesignList.value.bodyfontUrl) && !hasLoadedHeaderFonts) {
      const headerFont =  tenantDesignList.value?.headerfontUrl;
      const bodyFont = tenantDesignList.value?.bodyfontUrl;
      hasLoadedHeaderFonts = true;
      setFontUrls({headerFont, bodyFont});
    }
    if (tenantDesignList.value.twitterShareImageUrl && !hasLoadedFacebookImg) {
      await loadFacebookImg();
      hasLoadedFacebookImg = true;
    }
    if (tenantDesignList.value.twitterShareImageUrl && !hasLoadedTwitterImg) {
      await loadTwitterImg();
      hasLoadedTwitterImg = true;
    }
    if (tenantDesignList.value.faviconUrl && !hasLoadedFavicon) {
      await loadFavicon();
      hasLoadedFavicon = true;
    }
    if (tenantDesignList.value.logoUrl && hasLoadedLogo) {
      await loadLogo();
      hasLoadedLogo = true;
    }

  } catch (e) {
    console.error(e);
  }
});

interface Error {
  message: string,
  code?: number,
  channelId?: string,
  mediaId?: string,
  occurred?: boolean
}

onErrorCaptured((e: Error) => {
  if (e.channelId) {
    router.push(`/404/channel-${e.channelId}${e.mediaId ? "&Media-" + e.mediaId : ""}`);
  }
  // provide dedicated error to show network error
  // {
  //   router.push("/network-error");
  // }

});

onBeforeMount(async function() {
  // set language
  locale.value = publicPortalLanguage.value;
  try {
    await getTenantResources();
    setRootsVariables("tenant");

  } catch (e) {
    console.error(e);
  }
});


</script>

<style lang="postcss">
.router-view {
  @apply min-h-full flex;
  font-family: 'bodyFont';
}

#main-image {
  /* @apply rounded-[v-bind("config.view.rounded")]; */
}

.button-footer {
  @apply p-2;
}

/* Fade in and out for components */
.layout {
  .body-2 {
    @apply text-[0.875rem]
  }

  .body-1 {
    @apply text-base
  }

  h1 {
    @apply text-[6rem] font-bold
  }

  h2 {
    @apply text-[3.75rem] font-bold
  }

  h3 {
    @apply text-[3rem] font-bold
  }

  h4 {
    @apply text-[2.125rem] font-semibold
  }

  h5 {
    @apply text-[1.75rem] font-semibold
  }

  h6 {
    @apply text-[1.25rem] font-semibold
  }
}

/* Global Reset */
li {
  @apply list-none
}

/* Fade in and out for components */
.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 250ms ease, transform 250ms ease-in-out;
}

.fade-up-enter-from,
.fade-up-leave-to {
  opacity: 0;
  position: absolute;
  transform: translateY(3rem);
}

.fade-enter-active,
.fade-move-active,
.fade-leave-active {
  transition: opacity 350ms ease;
}

.fade-from,
.fade-leave-to {
  opacity: 0;
}

.fade-absolute-enter-active,
.fade-absolute-move-active,
.fade-absolute-leave-active {
  transition: opacity 350ms ease;
}

.fade-absolute-from,
.fade-absolute-leave-to {
  opacity: 0;
  position: absolute;
  z-index: -1000;
}


/* Default scrollbar style if used*/
::-webkit-scrollbar {
  @apply bg-neutral-200 rounded w-2;
}

::-webkit-scrollbar-thumb {
  @apply bg-blue-500 hover:bg-blue-300 rounded;
}

/* Custom router active class */
.router--active {
  @apply bg-dark-300 text-light-100
}

/* Transitions */
.slide-left-enter-active {
  transition: transform 150ms ease-in, opacity 150ms ease-in;
}

.slide-left-leave-active {
  transition: transform 150ms ease-out, opacity 150ms ease-in;
}

.slide-left-enter-from,
.slide-left-leave-to {
  @apply -translate-x-full opacity-0
}

.fade-down-enter-active {
  transition: transform 150ms ease-in, opacity 150ms ease-out;
}

.fade-down-leave-active {
  transition: transform 150ms ease-out, opacity 150ms ease-in;
}

.fade-down-enter-from,
.fade-down-leave-to {
  @apply opacity-0 -translate-y-full;
}

.slide-fade-right-enter-active,
.slide-fade-right-move,
.slide-fade-right-leave-active {
  transition: all 0.5s ease-out;
}

.slide-fade-right-enter-from,
.slide-fade-right-leave-to {
  transform: translateX(100px);
  opacity: 0;
}


/* SPACINGS */
.panel-b-spacing {
  @apply my-3
}
</style>
