<template>
  <header class="header">
    <div class="header__title-container">
      <base-selector v-if="mode === 'channel' && !searchResult" @get:selection="getSelectedMedia" selected-tag="h5" :options="mediaItems"
                     :tabindex="0" :default="activeOption" />
      <template v-if="mode === 'channels' || searchResult">
        <base-title tag="h5" class="title">
          {{ translatedTitle }}
        </base-title>
        <span class="ml-1 title">({{ count }})</span>
      </template>
    </div>
    <portal-controls />
  </header>
</template>

<script lang="ts" setup>
// ** IMPORT COMPONENTS ** //
import BaseTitle from "@/components/library/typography/BaseTitle.vue";
import PortalControls from "@/components/library/portal/controls/PortalControls.vue";
// ** IMPORT STORES ** //
import { useStyleStore } from "@/stores/useStyleApp";
// ** IMPORT COMPOSABLES ** //
import { useChannelsStore } from "@/stores/useChannels";
// ** IMPORT UTILS ** //
import { useI18n } from "vue-i18n";
// ** IMPORT FUNCTIONS ** //
import { storeToRefs } from "pinia";
import { ref, computed, inject, watchEffect } from "vue";
import BaseSelector from "@/components/library/dropdowns/BaseSelector.vue";
import { useChannelStore } from "@/stores/useChannel";
import { useConfigStore } from "@/stores/useConfig";
import { useRoute } from "vue-router";
import { useSearchStore } from "@/stores/useSearch";

// ** STORES ** //
const styleStore = useStyleStore();
const { filters, updateFilter, resetPageCount } = useConfigStore();
const { fetchMedia, state: channelState } = useChannelStore();
const { state: channelsState } = useChannelsStore();
const searchStore = useSearchStore();
const { body, header } = storeToRefs(styleStore);
const { result: searchResult, totalCount: searchTotalCount } = storeToRefs(searchStore);
// ** COMPOSABLES ** //
const { t } = useI18n();
const route = useRoute();
// ** UTILS ** //

// ** Type Declarations ** //
type Titles = "allChannels" | "allMedia" | "files" | "videos" | "images"
type Modes = "channels" | "channel"

const mode = inject<Modes>("mode");
const title = ref<Titles>("allChannels");
const activeOption = ref<null | object>(null);

const count = computed(() => {
  if (searchResult.value) {
    return searchTotalCount.value;
  }
  if (mode === "channels") {
    return channelsState.totalCount;
  }
  if (mode === "channel") {
    return channelState.totalCount;
  }
});

const translatedTitle = computed(() => {
  if (searchResult.value) {
    return t("search.result");
  }
  if (mode === "channels") {
    title.value = "allChannels";
  } else if (mode === "channel") {
    title.value = "allMedia";
  }
  return t(`portalHeader.dropdown.${title.value}`);
});

const mediaItems = computed(() => {
  return [{
    value: "allMedia",
    count: channelState.totalCount,
    translation: t("types.all"),
    icon: {
      name: "icons",
      type: "fal"
    }
  },
    {
      value: "video",
      count: channelState.totalVideoCount,
      translation: t("types.video"),
      icon: {
        name: "film",
        type: "fas"
      }
    },
    {
      value: "image",
      count: channelState.totalImageCount,
      translation: t("types.image"),
      icon: {
        name: "image",
        type: "far"
      }
    },
    {
      value: "audio",
      count: channelState.totalAudioCount,
      translation: t("types.audio"),
      icon: {
        name: "music",
        type: "far"
      }
    }
  ];
});

watchEffect(() => {
  // Keeps the UI in sync what comes from persistent store
  const typeValue = filters[mode].type;
  const activeIndex = mediaItems.value.findIndex(option => option.value === typeValue);
  if (activeIndex === -1) {
    activeOption.value = false;
  } else {
    activeOption.value = mediaItems.value[activeIndex];
  }
});

async function getSelectedMedia({ value }) {
  updateFilter({ update: { value, key: "type" }, storeKey: mode });
  resetPageCount(mode)
  await fetchMedia(route.params.channelId);
}

</script>

<style lang="postcss" scoped>
.header {
  @apply p-4 flex items-center justify-between rounded-t-md text-black bg-[#F2F2F2];
  /*@apply p-4 flex items-center justify-between rounded-t-md text-black bg-dark-50; */

  &__title-container {
    @apply flex items-center font-semibold;

    :deep(.radio) {
      @apply hidden
    }

    .title,
    :deep(.container__selected-value) {
      @apply !font-bold text-lg text-black;
    }

    :deep(.selector-container__selected-item) {
      @apply hidden
    }

    :deep(.chevron-down) {
      @apply -order-1 border-2 text-dark-500 border-dark-500 p-3 rounded-full
    }

  }

}

.header h1 {
  @apply font-bold;
}

.header select {
  @apply bg-transparent border-none outline-none hidden sm:block;
}

/* ACTIVE STYLES */
svg.selected {
  @apply text-neutral-500
}
</style>
