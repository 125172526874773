<template>
  <div class="relative" :class="{'w-screen h-screen':appState.isStandAlone && !playerOptions?.smallPlayer, 'vertical': vertical && !appState.mediaCover, 'force-cover': appState.mediaCover}">

    <video :loop="appState.isLoop" :key="id" @click="handleRouting" ref="mediaPlayer" :data-matomo-title="id"
           class="video-js player-wrapper__player"
           :class="[appState.isStandAlone && 'no-border', {'padding-min': vertical && !appState.mediaCover}]"
           :poster='posterImage'
           tabindex="1">
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to
        a web browser that supports HTML5 video
      </p>
    </video>
    <base-button
      v-if="playerOptions && playerOptions.smallPlayer"
      class="mute-btn"
      btn-type="action"
      @click.native="toggleMute"
      :icon="mutedIcon"
      :tooltip="isMuted ? $t('button.unmute') : $t('button.mute')"
    >
    </base-button>
  </div>
</template>
<script setup lang="ts">
import videojs from "video.js";
import '@sd/sd-player-plugin';

import { computed, onMounted, ref, watch, inject, onBeforeUnmount, onUnmounted } from 'vue';
import { useConfigStore } from "@/stores/useConfig";
import BaseButton from "@/components/library/buttons/BaseButton.vue";

const { apiConfig, appSettings, publicPortalLanguage } = useConfigStore();

import { useRouter, useRoute } from "vue-router";
import { Emitter } from "mitt";
import { useAppStore } from "@/stores/useApp";
import { useFetchFullURL } from '@/utils/useService';

const { state: appState } = useAppStore();

interface Props {
  id: string;
  rendition: {
    source: string,
    mimeType: string,
    subtitles: []
  };
  thumbnail: {
    url: string
  };
  cleanPlayer?: boolean,
  route?: string;
  duration: string;
  playerOptions?: {
    smallPlayer?: boolean,
    fluid: boolean,
    autoplay: boolean,
    controls: boolean,
    preload: string,
    aspectRatio: string,
    controlBar: {
      volumePanel: { inline: boolean }
    },
    html5?: {
      nativeAudioTracks: boolean,
      nativeVideoTracks: boolean,
      vhs: {
        overrideNative: boolean
      }
    }
  };
}

const props = withDefaults(defineProps<Props>(), {
  playerOptions: {
    fluid: true,
    autoplay: false,
    controls: true,
    preload: "auto",
    controlBar: {
      volumePanel: { inline: false }
    },
    html5: {
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      nativeTextTracks: false,
      vhs: {
        overrideNative: false,
        bandwidth: 5260000,
        limitRenditionByPlayerDimensions: false,
        useDevicePixelRatio: true,
      },
      hls: {
        overrideNative: false,
        bandwidth: 5260000,
        limitRenditionByPlayerDimensions: false,
        useDevicePixelRatio: true,
      },
    },
  },
  cleanPlayer: false
});

const mediaPlayer = ref(null);
const isMuted = ref(true);
const posterImage = ref(null);
const hasBeenActivatedByUser = ref(false);
const transcriptionEditorInitiatedSeek = ref(false);
const router = useRouter();
const route = useRoute();
const emitter = inject<Emitter<any>>("emitter");
let videojsPlayer;
const vertical = computed(() => {
  return props.thumbnail?.height > props.thumbnail?.width;
});

const landscapeView = ref(window.innerHeight <= 600 &&
  !window.matchMedia("(orientation: portrait)").matches);

const mutedIcon = computed(() => {
  return isMuted.value ? {
    name: "volume-slash", type: "far"
  } : {
    name: "volume", type: "far"
  };
});

const sourceLink = computed(() => {
  if (!route.query.start && !route.query.end) return props.rendition.source;

  const startTime = Number(route.query.start ? route.query.start : 0) * 1000;
  const endTime = Number(route.query.end ? route.query.end : duration.value) * 1000;
  const sourceUrl = props.rendition.source + '?start=' + startTime + '&duration=' + (endTime - startTime);
  if (!props.rendition.type?.includes("mpegURL")) {
    return sourceUrl + '&wowzaplaystart=' + startTime + '&wowzaplayduration=' + (endTime - startTime);
  }else{
    return sourceUrl;
  }
});

const duration = computed(() => {
  const [hours, minutes, seconds] = props.duration.split(":");
  return (parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds)) * 1000;
})

const textTracks = computed(() => {
  let subtitles: any[] = props.rendition.subtitles;
  if (appState.captionOn && subtitles?.length > 0) {
    if (subtitles.length > 1) {
      subtitles = props.rendition.subtitles.map(track => {
        if (publicPortalLanguage.value == track.languageCode) {
          track.default = true;
        }
        return track;
      });
    } else {
      subtitles[0].default = true;
    }
  }
  return subtitles ? subtitles : [];
})

function init() {
  if (!mediaPlayer.value) return;

  const pluginOptions = {
    token: apiConfig.token,

    playerSettings: {
      ...appSettings.tenantDesign.playerDesign,
      tracks: { textTracks: textTracks.value },
      language: publicPortalLanguage.value
    },
    showChapter: true,
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
  };
  videojs.hookOnce("setup", (p) => {
    if (props.cleanPlayer) {
      pluginOptions.playerSettings.cleanPlayer = true;
    }
    if(p.sdPlugin){
      window.sdPlugin = p.sdPlugin(pluginOptions);
    }
  });
  videojs.log();
  const playerOptions = {...props.playerOptions, autoplay: appState.autoPlay ? true : false, controls: appState.hideControls ? false : true};

  videojsPlayer = videojs(mediaPlayer.value, { ...playerOptions, useForcedSubtitles: true
  });

  videojsPlayer.ready(() => {
    if(appState.hideControls) {
      let controlbar =  document.querySelector(".vjs-control-bar");
      controlbar.parentNode.removeChild(controlbar);
    }
    emitter.emit('sd-player-ready');
    const type = props.rendition.mimeType
      ? props.rendition.mimeType
      : 'application/x-mpegurl';
    videojsPlayer.src([
      {
        src: sourceLink.value,
        type: type
      }
    ]);
    emitter.on("player-jump-to-seconds", (value) => {
      userActivatedPlay();
      transcriptionEditorInitiatedSeek.value = true;
      moveTo(value);
      videojsPlayer.play();
    });

    emitter.on('sd-player-load-chapters', (chapters) => {
        videojsPlayer.setState({
          chapters
        });
      if(videojsPlayer.state.chapters) {
        videojsPlayer.trigger('loadChapters');
      }
    });
  });
  if(props.playerOptions.autoplay || appState.autoPlay) {
    videojsPlayer.muted(true);
    videojsPlayer.play();
  }
  if (appState.isStandAlone && !props.playerOptions.smallPlayer) {
    videojsPlayer.fill(true);
  }


  // Hide PIP mode on Audio streams
  const pipBtn = document.querySelector(".vjs-picture-in-picture-control");
  if (!props.rendition.type?.includes("mpegURL") && pipBtn) {
    pipBtn.style.visibility = "hidden";
  } else {
    pipBtn.style.visibility = "visible";
  }

  // Emits Player time
  videojsPlayer.on("timeupdate", () => {
    emitter.emit("get:playerTime", videojsPlayer.currentTime());
  });
  videojsPlayer.on('play', () => {
    emitter.emit('sd-player-play');
  })
}

function toggleMute() {
  if (videojsPlayer) {
    if (videojsPlayer.muted()) {
      videojsPlayer.muted(false);
      isMuted.value = false;
    } else {
      videojsPlayer.muted(true);
      isMuted.value = true;
    }
  }
}

function handleRouting() {
  if (props.route) {
    router.push(props.route);
  }
}
function userActivatedPlay() {
  // emit action that user activated playback
  emitter.emit("sdplayer-user-activate-playback");
  // removeEventListener from big play button
  const bigPlayButton = document.getElementsByClassName(
    "vjs-big-play-button"
  );
  if (bigPlayButton.length) {
    bigPlayButton[0].removeEventListener("click", userActivatedPlay);
  }
  if (!hasBeenActivatedByUser.value) {
    // removeEventListener from player overlay
    const playerOverlay = document.getElementById("vjs_video_3_html5_api");
    if (document.getElementById("vjs_video_3_html5_api")) {
      playerOverlay.removeEventListener("click", userActivatedPlay);

      playerOverlay.addEventListener("click", () => {
        if (videojsPlayer.paused()) {
          // if user pressed player to pause playback we need to set a flag to not unpause video while typing
          emitter.emit("sdplayer-user-paused", true);
          hasBeenActivatedByUser.value = false;
        } else {
          hasBeenActivatedByUser.value = true;
          emitter.emit("sdplayer-user-paused", false);
        }
      }, { once: true });
    }
    hasBeenActivatedByUser.value = true;
  }
}

function moveTo(seconds) {
  if (seconds < 0) {
    seconds = 0;
  }
  videojsPlayer.currentTime(seconds);
}

onMounted(async () => {
  if(props.thumbnail){
    await fetchThumbnail(props.thumbnail);
  }
  init();
  if(route.query.searchStart > 0 ) {
    moveTo(route.query.searchStart);
  }
  setLandscapeView();
  window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
    landscapeView.value = window.innerHeight <= 600 && !e.matches;
    setLandscapeView();
  });

  window._paq.push(['MediaAnalytics::setPingInterval', 10]);
  window._paq.push(['MediaAnalytics::scanForMedia']);
});
async function setLandscapeView() {
  if (landscapeView.value) {
    document.getElementsByClassName("video-js")[0].classList.add('landscape-view')
  } else {
    document.getElementsByClassName("video-js")[0].classList.remove('landscape-view')
  }
}
async function fetchThumbnail(data) {
  if (!data) return;
  const { url = "" } = data;
  if (url === "") return;
  try {
    const response = await useFetchFullURL(url);
    const imageBlob = await response.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    posterImage.value = imageObjectURL;

  } catch (e) {
    console.error(e);
  }
}
onBeforeUnmount(() => {
  // videojsPlayer.exitPictureInPicture();/**/
  emitter.emit('sd-player-removed');
  emitter.off("player-jump-to-seconds");
  emitter.off("sd-player-load-chapters");
});
onUnmounted(() => {
  videojsPlayer.dispose();
  videojsPlayer = null;
  window.matchMedia("(orientation: portrait)").removeEventListener("change", e => {
    landscapeView.value = window.innerHeight <= 600 && !e.matches;
    setLandscapeView();
  });
  console.log('unmounted')
})
</script>

<style scoped lang="postcss">
.mute-btn {
  @apply absolute top-1 right-1 text-xl bg-dark-750 text-white px-4 py-1 rounded-lg;
}

:deep(.video-js.vjs-sd-plugin) {
  @apply shadow-none;
}
.padding-min {
  @apply h-full min-h-[calc(100vh-20rem)];
  padding-top: 0 !important;
}
.landscape-view {
  @apply h-full min-h-[calc(100vh-7rem)];
  padding-top: 0 !important;
}
.vertical {
  .video-js, .vjs-tech {
    object-fit: contain;
  }
  .vjs-poster img {
    object-fit: contain;
  }
}
.force-cover {
  .video-js, .vjs-tech {
    object-fit: cover !important;
  }
  .vjs-poster img {
    object-fit: cover !important;
  }
}
.no-border.vjs-sd-plugin {
  @apply !rounded-none md:!rounded-none lg:!rounded-none xl:!rounded-none;
}
.force-cover {
  .video-js, .vjs-tech {
    object-fit: cover !important;
  }
  :deep(.vjs-poster img) {
    object-fit: cover !important;
  }
}
</style>