import { acceptHMRUpdate, defineStore } from "pinia";
import { reactive, ref } from "vue";
import api from "@/services/tenantService";
import { useI18n } from "vue-i18n";
import { useStyleStore } from "@/stores/useStyleApp";

export const useConfigStore = defineStore("configStore", () => {
  // @ts-ignore
  const BASE_URL = import.meta.env.VITE_DOMAIN_URL;
  // @ts-ignore
  const TOKEN_BASE_URL = import.meta.env.VITE_PUBLIC_TOKEN_URL;

  const { availableLocales } = useI18n();
  const preferredLanguage = navigator.language.slice(0, 2);
  const publicPortalLanguage = ref({
    value: availableLocales.includes(preferredLanguage) ? preferredLanguage : availableLocales[0],
    translation: availableLocales.includes(preferredLanguage) ? preferredLanguage : availableLocales[0]
  });

  const apiConfig = reactive({
    baseUrl: BASE_URL,
    tokenBaseUrl: TOKEN_BASE_URL,
    tenantName: window.location.host.split(".")[0].indexOf("test-") > -1 ? window.location.host.split(".")[0] : window.location.host.split(".")[0].indexOf("dev-") > -1 ? "dev-internal" : window.location.host.split(".")[0],
    token: null,
    isFetchingToken: false,
    payloadConfig: {
      channels: {
        PER_PAGE: 20,
        MAX_PAGE: null,
        page: 0 // default Page
      },
      channel: {
        PER_PAGE: 20,
        MAX_PAGE: null,
        page: 0 // default Page
      }
    }
  });

  const { setTenantDesign } = useStyleStore();

  const appSettings = reactive({ layout: "default", tenantSettings: {}, tenantDesign: {}, 'isMobileNavOpen': false });

  const filters = reactive({
    "channels": {
      property: "name",
      sort: "" // '' -> '' ascending default && ':desc' descending
    },
    "channel": {
      property: "name",
      sort: "", // '' -> '' ascending default && ':desc' descending
      type: "allMedia"
    }
  });

  const portalView = reactive({ channels: "grid", channel: "grid" }); // default;

  async function getTenantResources() {
    try {
      const res = await api.getSettings();
      const { data, status } = await res.json();
      if (status === "success") {
        // TYPO DESIGNS
        console.log(data)
        window["_paq"].push(['setSiteId', data.matomoSiteId]); //matomoSiteId - take it from GET /tenants/current/settings (mandatory)
        appSettings.tenantSettings = data;
        const fetchCurrentDesign = await api.getCurrentDesign();
        const {data: designPayload, status: designStatus} = await fetchCurrentDesign.json();

        if(designStatus === 'success'){
            setTenantDesign({...designPayload.channelDesign, logoUrl: designPayload.centerDesign.logoUrl, faviconUrl: designPayload.centerDesign.faviconUrl});
            appSettings.tenantDesign = designPayload;
        }

      }
      return { data, status };
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  function setLayout(layout) {
    appSettings.layout = layout;
  }

  function setMaxPage({ storeKey, count }) {
    const payloadCount = apiConfig.payloadConfig[storeKey].PER_PAGE;
    apiConfig.payloadConfig[storeKey].MAX_PAGE = Math.ceil(+count / +payloadCount);
  }

  function updateFilter({ update: { value, key }, storeKey }) {
    filters[storeKey][key] = value;
  }

  function setPortalLanguage(languageShortCode: string) {
    publicPortalLanguage.value.value = languageShortCode;
    publicPortalLanguage.value.translation = languageShortCode;
  }

  function updatePortalView(portal, newLayout) {
    portalView[portal] = newLayout;
  }

  function incrementPageCount(storeKey) {
    ++apiConfig.payloadConfig[storeKey].page;
  }

  function resetPageCount(storeKey) {
    apiConfig.payloadConfig[storeKey].page = 0;
  }

  const fetchToken = async () => {
    if (apiConfig.token === null) {
      apiConfig.isFetchingToken = true;
      let tenantN = apiConfig.tenantName;
      if (apiConfig.tenantName.includes("localhost")) {
        tenantN = "dev-internal";
      } else {
        tenantN = apiConfig.tenantName;
      }
      await fetch(TOKEN_BASE_URL + tenantN)
        .then(response => {
          return response.json();
        })
        .then(data => {
          apiConfig.token = "Bearer " + data.accessToken;
          return data;
        }).catch(e => {
          console.error(e);
          throw e;
        }).finally(() => {
          apiConfig.isFetchingToken = false;
        });
    }
  };

  function toggleMobileMenu() {
    appSettings.isMobileNavOpen = !appSettings.isMobileNavOpen;
  }

  return {
    apiConfig,
    filters,
    portalView,
    publicPortalLanguage,
    appSettings,
    updateFilter,
    setMaxPage,
    setLayout,
    updatePortalView,
    fetchToken,
    setPortalLanguage,
    getTenantResources,
    incrementPageCount,
    resetPageCount,
    toggleMobileMenu
  };
}, {
  persist: {
    paths: ["filters", "portalView", "publicPortalLanguage"],
    key: "public-portal-settings"
  }
});

// Import HMR module for pinia
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot));
}
