import { defineStore, acceptHMRUpdate } from "pinia";
import { useAsyncFetch } from "@/composables/useAsyncFetch";
import { reactive, computed } from "vue";
import api from "@/services/mediaService";
import metaApi from "@/services/metadataService";
import { Media } from "@/types/mediaTypes";

export const useMediaStore = defineStore("mediaStore", () => {

    const state = reactive({
      details: {},
      metadata: null,
      transcription: null,
      transcriptConfig: {
        autoscroll: false
        // autoPause: false
      },
      player: {
        currentTime: 0
      }
    });

    async function fetchDetails(id) {
      const { state: resState } = await useAsyncFetch<Media, Object>(api.getDetails(id));
      state.details = resState.data;
      return state;
    }

    async function fetchChapters(mediaId) {
      const { state: resState } = await useAsyncFetch(api.getChapters(mediaId));
      return resState.data;
    }

    async function fetchChapter(mediaId, chapterId) {
        const { state: resState } = await useAsyncFetch(api.getChapterById(mediaId, chapterId));
        return resState.data;
    }
    async function fetchDetailsFromChannel(channelId, mediaId) {
      const { state: resState } = await useAsyncFetch<Media, Object>(api.getDetailsFromChannel(channelId, mediaId));
      state.details = resState.data;
      return state;
    }

    function getPlaylistUrl(mediaType, renditions) {
      if (mediaType === "video") {
        const rendition = renditions.find(r => r.mimeType === "application/x-mpegURL");
        if (!rendition) {
          throw new Error("Mimetype for xmpegUrl not found in renditions.");
        } else {
          return rendition;
        }
      }
      if (mediaType === "audio") {
        const rendition = renditions.find(r => r.source);
        if (!rendition) {
          throw new Error("Audio type not found in renditions.");
        } else {
          return rendition;
        }
      }
    }

    async function getTranscription({ mediaId, transcriptId }) {
      const { state: res } = await useAsyncFetch(metaApi.fetchTranscript({ mediaId, transcriptId }));
      const transcriptions = res.data.paragraphs;
      transcriptions.forEach(t => {
        t.id = t.paragraphId;
      });
      state.transcription = res.data;
      return res;
    }

    async function getMetadata(id) {
      const { state: res } = await useAsyncFetch(metaApi.fetchMetadata(id));
      state.metadata = res.data;
      return res;
    }

    function setTranscriptConfig({ key, value }) {
      state.transcriptConfig[key] = value;
    }

    function getActiveTranscript() {
      return state.metadata?.transcripts.find(t => t.isActive);
    }

    function setPlayerTime(time) {
      return state.player.currentTime = time;
    }

    function resetStore() {
      const defaultState = {
        details: {},
        metadata: null,
        transcription: null,
        transcriptConfig: {
          autoscroll: false
          // autoPause: false
        },
        player: {
          currentTime: 0
        }
      };
      for (let key in defaultState) {
        state[key] = defaultState[key];
      }
    }

    const activeTranscript = computed(() => {
      return state.metadata?.transcripts.find(t => t.isActive);
    });

    return {
      state,
      activeTranscript,
      getActiveTranscript,
      fetchChapter,
      fetchDetails,
      getPlaylistUrl,
      getTranscription,
      getMetadata,
      setTranscriptConfig,
      setPlayerTime,
      resetStore,
      fetchDetailsFromChannel,
      fetchChapters
    };
  })
;
// Import HMR module for pinia
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useMediaStore, import.meta.hot));
}
